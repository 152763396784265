import { render, staticRenderFns } from "./WorkGroupModal.vue?vue&type=template&id=720fa556&scoped=true"
import script from "./WorkGroupModal.vue?vue&type=script&lang=js"
export * from "./WorkGroupModal.vue?vue&type=script&lang=js"
import style0 from "./WorkGroupModal.vue?vue&type=style&index=0&id=720fa556&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720fa556",
  null
  
)

export default component.exports