<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         footer-hide
         :title="title"
         width="700"
         class-name="vertical-center-modal">
    <div>
      <div class="role"
           v-if="data.workRuleId">
        <!-- 车或人 -->
        <span>{{data.workRuleName || data.workRule}}</span>
        <p @click="onClickUnbind">解绑</p>
      </div>
      <Table ref="table"
             :columns="columnsList"
             :data="tableData"
             height="400"
             border
             stripe
             @on-row-click="onRowClickTable"
             :loading="tableLoading">
      </Table>
      <Page class="text-right p-t-10"
            :total="totalPage"
            :current="pageNum"
            :page-size="pageSize"
            show-total
            show-sizer
            show-elevator
            @on-change="onPageChange"
            @on-page-size-change="onPageSizeChange" />
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '规则'
    },
    data: Object,
    type: String
  },
  data () {
    return {
      tableData: [],
      columnsList: [
        {
          title: '规则名称',
          key: 'ruleName',
          tooltip: true,
          width: 160,
          align: 'center'
        },
        {
          title: '规则详情',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let str = ''
            if (row.ruleItems) {
              let arr = row.ruleItems.map((item) => {
                let itemArr = []
                if (item.isEnable == 1) {
                  for (const i in item.content) {
                    if (i === 'scope') {
                      itemArr.push('范围' + item.content.scope + '米')
                    }
                    if (i === 'time') {
                      itemArr.push('时间' + item.content.time + '分钟')
                    }
                    if (i === 'yxc') {
                      itemArr.push('允许出' + item.content.yxc + '次')
                    }
                    if (i === 'dczc') {
                      itemArr.push('单次最长' + item.content.dczc + '分钟')
                    }
                    if (i === 'cwgpd') {
                      itemArr.push('出网格判定' + item.content.cwgpd + '分钟')
                    }
                    if (i === 'spwld') {
                      itemArr.push('扫盘未落地' + item.content.spwld + '分钟')
                    }
                    if (i === 'ptwkq') {
                      itemArr.push('喷头未开启' + item.content.ptwkq + '分钟')
                    }
                    if (i === 'tl') {
                      itemArr.push('停留' + item.content.tl + '分钟')
                    }
                    if (i === 'wdd') {
                      itemArr.push('未达到' + item.content.wdd + '%')
                    }
                    if (i === 'zx') {
                      itemArr.push('最小' + item.content.zx + 'km/h')
                    }
                    if (i === 'zd') {
                      itemArr.push('最大' + item.content.zd + 'km/h')
                    }
                    if (i === 'ccl') {
                      itemArr.push('超出量' + item.content.ccl + '%')
                    }
                    if (i === 'wd') {
                      itemArr.push('晚到' + item.content.wd + '分钟')
                    }
                  }
                }
                return itemArr.join()
              })
              arr = arr.filter(item => item)
              str = arr.join()
            }
            return h('span', str)
          }
        },
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
    };
  },
  watch: {
    data (newValue) {
      // if (newValue.name || newValue.workPlanName) {
      this.getList()
      // }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    getList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        module: this.type
      }
      this.tableLoading = true
      this.$http.getWorkRuleList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.tableData = res.result.workRules
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 单击表格行，绑定规则
    onRowClickTable (value, index) {
      if (value.workRuleId == this.data.workRuleId) {
        this.$Message.info('当前规则已绑定此计划,请先解绑')
      } else {
        this.$Modal.confirm({
          title: '绑定',
          content: '确认绑定?',
          onOk: () => {
            if (this.type == 2) {
              this.bindRoadClean(value)
            } else if (this.type == 3) {
              this.bindMechanicalClean(value)
            } else if (this.type == 4) {
              this.bindGarbageClean(value)
            } else if (this.type == 5) {
              this.bindGarbageTransit(value)
            }
          }
        })
      }
    },
    // 解绑规则
    onClickUnbind () {
      this.$Modal.confirm({
        title: '解绑',
        content: '确认解绑?',
        onOk: () => {
          if (this.type == 2) {   //  道路保洁
            this.unbindRoadClean()
          } else if (this.type == 3) {
            this.unBindMechanicalClean()  // 机械清扫
          } else if (this.type == 4) {
            this.unBindGarbageClean() // 垃圾清运
          } else if (this.type == 5) {
            this.unBindGarbageTransit() // 垃圾转运
          }
        }
      })
    },
    // 道路保洁解绑
    unbindRoadClean () {
      let params = {
        uuId: this.data.uuId,
        workRule: ''
      }
      this.$store.dispatch('createOrEditRoadCleanPlan', params).then(res => {
        // this.getList()
        this.data.workRule = ''
        this.data.workRuleId = ''
        // this.$store.dispatch('updateCarTypeAllList', true)
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 道路保洁绑定
    bindRoadClean (value) {
      let params = {
        uuId: this.data.uuId,
        workRule: value.workRuleId
      }
      this.$store.dispatch('createOrEditRoadCleanPlan', params).then(res => {
        // this.getList()
        this.data.workRule = value.ruleName
        this.data.workRuleId = value.workRuleId
        // this.$store.dispatch('updateCarTypeAllList', true)
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 机械清扫  解绑
    unBindMechanicalClean () {
      let params = {
        carPlanIdList: [this.data.id],
        ruleId: '',
        flag: 2,
        type: 1,
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        // this.getList()
        this.data.workRuleName = ''
        this.data.workRuleId = ''
        // this.$store.dispatch('updateCarTypeAllList', true)
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 机械清扫  绑定
    bindMechanicalClean (value) {
      let params = {
        carPlanIdList: [this.data.id],
        ruleId: value.workRuleId,
        flag: 2,
        type: 1,
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.data.workRuleName = value.ruleName
        this.data.workRuleId = value.workRuleId
        // this.$store.dispatch('updateCarTypeAllList', true)
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 垃圾清运  解绑
    unBindGarbageClean () {
      let params = {
        carPlanIdList: [this.data.id],
        ruleId: '',
        flag: 2,
        type: 2,
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.data.workRuleName = ''
        this.data.workRuleId = ''
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 垃圾清运  绑定
    bindGarbageClean (value) {
      let params = {
        carPlanIdList: [this.data.id],
        ruleId: value.workRuleId,
        flag: 2,
        type: 2,
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.data.workRuleName = value.ruleName
        this.data.workRuleId = value.workRuleId
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 垃圾转运   解绑
    unBindGarbageTransit () {
      let params = {
        carPlanIdList: [this.data.id],
        ruleId: '',
        flag: 2,
        type: 3,
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.data.workRuleName = ''
        this.data.workRuleId = ''
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 垃圾转运  绑定
    bindGarbageTransit (value) {
      let params = {
        carPlanIdList: [this.data.id],
        ruleId: value.workRuleId,
        flag: 2,
        type: 3,
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.data.workRuleName = value.ruleName
        this.data.workRuleId = value.workRuleId
        // this.$store.dispatch('updateCarTypeAllList', true)
      }).catch(err => {
        this.$Message.info(err.message)
      })
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:data', {})
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.role {
  line-height: 40px;
  padding: 0 18px;
  margin-bottom: 16px;
  font-size: 12px;
  color: #6c6d6e;
  background-color: #fafbfc;
  display: flex;
  justify-content: space-between;
  p {
    color: #0079fe;
    cursor: pointer;
  }
}
</style>