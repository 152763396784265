<template>
  <Modal :value="visible"
         :title="title"
         width="550"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title">违规名称：</p>
          <Input v-model="data.customName"
                 placeholder=""
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <!-- 人员start -->
        <!-- 离岗 -->
        <template v-if="data.sysName=='离岗'">
          <li class="form-ul-li half">
            <p class="li-title">单次最长：</p>
            <Input v-model="data.content.dczc"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>
          <li class="form-ul-li half">
            <p class="li-title">出电子围栏：</p>
            <Input v-model="data.content.cwgpd"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>
          <li class="form-ul-li half">
            <p class="li-title">允许出：</p>
            <Input v-model="data.content.yxc"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">次</span>
          </li>
        </template>

        <!-- 停留 -->
        <template v-if="data.sysName=='停留'">
          <li class="form-ul-li half">
            <p class="li-title">范围：</p>
            <Input v-model="data.content.scope"
                   placeholder=""
                   maxlength="30"
                   v-floatLimit
                   class="input-right-5"></Input>
            <span class="li-unit">米</span>
          </li>
          <li class="form-ul-li half">
            <p class="li-title">时间：</p>
            <Input v-model="data.content.time"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>
        </template>

        <!-- 聚众 -->
        <template v-if="data.sysName=='聚众'">
          <li class="form-ul-li half">
            <p class="li-title">人数：</p>
            <Input v-model="data.content.rs"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">人</span>
          </li>
          <li class="form-ul-li half">
            <p class="li-title">范围：</p>
            <Input v-model="data.content.scope"
                   placeholder=""
                   maxlength="30"
                   v-floatLimit
                   class="input-right-5"></Input>
            <span class="li-unit">米</span>
          </li>
          <li class="form-ul-li half">
            <p class="li-title">时间：</p>
            <Input v-model="data.content.time"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>
        </template>

        <!-- 人员end -->

        <!-- 车辆start -->
        <!-- 车辆空驶 -->
        <template v-if="data.sysName=='车辆空驶'">
          <li class="form-ul-li half">
            <p class="li-title">扫盘未落地：</p>
            <Input v-model="data.content.spwld"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>
          <li class="form-ul-li half">
            <p class="li-title">喷头未开始：</p>
            <Input v-model="data.content.ptwkq"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>
        </template>

        <!-- 车辆停留 -->
        <template v-if="data.sysName=='车辆停留'">
          <li class="form-ul-li half">
            <p class="li-title">停留：</p>
            <Input v-model="data.content.tl"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>

          <li class="form-ul-li half">
            <p class="li-title">聚合时间：</p>
            <Input v-model="data.polymerizationtime"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">min</span>
          </li>
          <li class="form-ul-li">
            <p class="tip">聚合时间为间隔只要未超过聚合时间，则合并为一个违规，且时间叠加</p>
          </li>
        </template>

        <!-- 作业遗留 -->
        <template v-if="data.sysName=='作业遗留'">
          <li class="form-ul-li half">
            <p class="li-title">未达到：</p>
            <Input v-model="data.content.wdd"
                   placeholder=""
                   maxlength="30"
                   v-floatLimit
                   class="input-right-5"></Input>
            <span class="li-unit">%</span>
          </li>
        </template>

        <!-- 速度限制 -->
        <template v-if="data.sysName=='速度限制'">
          <li class="form-ul-li half">
            <p class="li-title">最大：</p>
            <Input v-model="data.content.zd"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">km/h</span>
          </li>
          <!-- <li class="form-ul-li half">
            <p class="li-title">最小：</p>
            <Input v-model="data.content.zx"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">km/h</span>
          </li> -->
          <li class="form-ul-li half">
            <p class="li-title">聚合时间：</p>
            <Input v-model="data.polymerizationtime"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">min</span>
          </li>
          <li class="form-ul-li">
            <p class="tip">聚合时间为间隔只要未超过聚合时间，则合并为一个违规，且时间叠加</p>
          </li>
        </template>

        <!-- 水量监测 -->
        <template v-if="data.sysName=='水量监测' || data.sysName=='油量监测'">
          <li class="form-ul-li half">
            <p class="li-title">超出量：</p>
            <Input v-model="data.content.ccl"
                   placeholder=""
                   maxlength="30"
                   v-floatLimit
                   class="input-right-5"></Input>
            <span class="li-unit">%</span>
          </li>
        </template>

        <!-- 晚到 -->
        <template v-if="data.sysName=='晚到'">
          <li class="form-ul-li half">
            <p class="li-title">晚到：</p>
            <Input v-model="data.content.wd"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit">分钟</span>
          </li>
        </template>

        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="data.remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '修改参数'
    },
    data: Object
  },
  data () {
    return {
      loading: false,
      type: ''
    }
  },
  watch: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 点击确认
    onClickConfirm () {
      if (this.data.workRuleItemId) {
        this.loading = true
        let params = {
          workRuleItemId: this.data.workRuleItemId,
          customName: this.data.customName,
          remark: this.data.remark,
          content: this.data.content,
        }
        if (this.data.sysName == '速度限制' || this.data.sysName == '车辆停留') {
          params.polymerizationtime = this.data.polymerizationtime
        }
        this.$store.dispatch('editWorkRuleItem', params).then(res => {
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
          // this.$store.dispatch('updateCarManufacturerAllList', true)
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      } else {
        this.$emit('onClickConfirm', this.data)
        this.$emit('onChange', false)
      }
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:data', {})
      }
    },
    resetData () {
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
