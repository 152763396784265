<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="title"
         width="700"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <div class="title-tip">
        <Icon custom="i-icon icon-jingshi"
              size="15"
              color="red"
              class="m-r-5" />
        当前平台设置为{{summerTimeFormat}}至{{winterTimeFormat}}为夏季作息时间，{{winterTimeFormat}}至{{summerTimeFormat}}为冬季作息时间
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>夏季开始时间：</p>
          <DatePicker type="date"
                      :value="summerTime"
                      @on-change="onChangeSummer"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>冬季开始时间：</p>
          <DatePicker type="date"
                      :value="winterTime"
                      @on-change="onChangeWinter"></DatePicker>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '设置冬夏时间'
    },
    dataId: Number
  },
  data () {
    return {
      loading: false,
      summerTime: '',
      summerTimeFormat: '',
      winterTime: '',
      winterTimeFormat: '',
    };
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getTimeCompleteDetail', this.dataId).then(res => {
        let time = new Date().getFullYear()
        let summer = res.summerDate.split('-')
        this.summerTimeFormat = summer[0] + '月' + summer[1] + '日'
        this.summerTime = time + '-' + res.summerDate
        let winter = res.winterDate.split('-')
        this.winterTimeFormat = winter[0] + '月' + winter[1] + '日'
        this.winterTime = time + '-' + res.winterDate
      })
    },
    onChangeSummer (value) {
      this.summerTime = value
    },
    onChangeWinter (value) {
      this.winterTime = value
    },
    // 确认
    onClickConfirm () {
      this.loading = true
      let params = {
        jobsetId: this.dataId,
        summerDate: this.summerTime,
        winterDate: this.winterTime
      }
      this.$store.dispatch('createOrEditTimeComplete', params).then(res => {
        this.loading = false
        // this.$store.dispatch('updatePersonTreeList', true)
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    // 取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', null)
        this.resetData()
      }
    },
    resetData () {
      this.summerTime = ''
      this.summerTimeFormat = ''
      this.winterTime = ''
      this.winterTimeFormat = ''
    },
  },
}
</script>

<style lang='scss' scoped>
.title-tip {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>