<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="900"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>规则名称：</p>
          <Input v-model="name"
                 placeholder="规则名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <div class="section-title">
          <span>规则设置</span>
        </div>
        <Table ref="table"
               :columns="columnsList"
               :data="tableData"
               height="300"
               border
               stripe
               :loading="tableLoading"
               class="form-table">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button type="text"
                    class="tableOperaBtn"
                    @click.stop="onClickEditRole(row)">修改</Button>
            <Button type="text"
                    class="tableOperaBtn"
                    @click.stop="onClickStatusRole(row)">{{row.isEnable===1?'禁用':'启用'}}</Button>
          </template>
        </Table>
        <li class="form-ul-li m-t-20">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>

    <!-- 修改规则参数 -->
    <WorkRuleDetailModal v-model="workRuleDetailVisible"
                         :data.sync="workRuleDetailData"
                         @onClickConfirm="onClickDetailConfirm"
                         @onClickCancel="getDetail"></WorkRuleDetailModal>
  </Modal>
</template>

<script>
import WorkRuleDetailModal from '@/components/product/admin/modal/work/WorkRuleDetailModal'
export default {
  components: {
    WorkRuleDetailModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '规则'
    },
    dataId: String,
    type: String
  },
  data () {
    return {
      loading: false,
      name: '', // 规则名称
      remark: '', // 备注
      tableLoading: false,
      pageNum: 1,
      pageSize: 20,
      tableData: [],
      columnsList: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysName',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规名称',
          key: 'customName',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let arr = []
            for (const i in row.content) {
              if (i === 'scope') {
                arr.push('范围' + row.content.scope + '米')
              }
              if (i === 'time') {
                arr.push('时间' + row.content.time + '分钟')
              }
              if (i === 'yxc') {
                arr.push('允许出' + row.content.yxc + '次')
              }
              if (i === 'dczc') {
                arr.push('单次最长' + row.content.dczc + '分钟')
              }
              if (i === 'cwgpd') {
                arr.push('出网格判定' + row.content.cwgpd + '分钟')
              }
              if (i === 'spwld') {
                arr.push('扫盘未落地' + row.content.spwld + '分钟')
              }
              if (i === 'ptwkq') {
                arr.push('喷头未开启' + row.content.ptwkq + '分钟')
              }
              if (i === 'tl') {
                arr.push('停留' + row.content.tl + '分钟')
              }
              if (i === 'wdd') {
                arr.push('未达到' + row.content.wdd + '%')
              }
              if (i === 'zx') {
                arr.push('最小' + row.content.zx + 'km/h')
              }
              if (i === 'zd') {
                arr.push('最大' + row.content.zd + 'km/h')
              }
              if (i === 'ccl') {
                arr.push('超出量' + row.content.ccl + '%')
              }
              if (i === 'wd') {
                arr.push('晚到' + row.content.wd + '分钟')
              }
            }
            return h('span', arr.join())
          }
        },
        {
          title: '启用状态',
          key: 'isEnable',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 150,
          align: 'center'
        }
      ],
      // 修改规则参数
      workRuleDetailVisible: false,
      workRuleDetailData: {}
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
    visible (newVal) {
      if (newVal && !this.dataId) {
        this.getRuleList()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getWorkRuleDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        this.tableData = res.ruleItems
      })
    },
    // 默认规则项
    getRuleList () {
      let params = {
        type: this.type
      }
      this.tableLoading = true
      this.$http.getWorkRuleItemList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.tableData = res.result
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改规则项
    onClickEditRole (row) {
      this.workRuleDetailVisible = true
      this.workRuleDetailData = row
    },
    // 修改规则项状态
    onClickStatusRole (row) {
      let index = this.tableData.findIndex(item => {
        return item.sysName == row.sysName
      })
      if (row.isEnable == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            if (row.workRuleItemId) {
              let params = {
                workRuleItemId: row.workRuleItemId,
                isEnable: 0
              }
              this.$store.dispatch('editWorkRuleItem', params).then(res => {
                this.getDetail()
                // this.$store.dispatch('updateCarManufacturerAllList', true)
              })
            } else {
              this.tableData[index].isEnable = 0
            }
          }
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            if (row.workRuleItemId) {
              let params = {
                workRuleItemId: row.workRuleItemId,
                isEnable: 1
              }
              this.$store.dispatch('editWorkRuleItem', params).then(res => {
                this.getDetail()
                // this.$store.dispatch('updateCarManufacturerAllList', true)
              })
            } else {
              this.tableData[index].isEnable = 1
            }
          }
        })
      }
    },
    // 规则项确认
    onClickDetailConfirm (data) {
      if (data.workRuleItemId) {
        this.getDetail()
      } else {
        let index = this.tableData.findIndex(item => {
          return item.sysName == data.sysName
        })
        this.tableData[index] = data
      }
    },
    // 点击确认
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入规则名称');
      this.loading = true
      let params = {
        name: this.name,
        remark: this.remark,
        ruleItems: this.tableData,
        status: 1,
        module: this.type
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditWorkRule', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // this.$store.dispatch('updateCarManufacturerAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  max-height: 600px;
  .section-title {
    border-bottom: none;
  }
  .form-table {
    width: 100%;
  }
}
</style>
