<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="1100"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>班组名称：</p>
          <Input v-model="name"
                 placeholder="班组名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="department"
                        placeholder="所属机构"
                        :data="departmentTreeArr"
                        search
                        style="width:100%"
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
      </ul>
      <div class="time">
        <div class="time-title">
          <div class="time-left">
            <span>上下班时间</span>
            <p @click.stop="onClickAddTime">
              <Icon custom="i-icon icon-tianjia1"
                    size="16" />
              新增时间段
            </p>
          </div>
          <div class="time-right flex a-center">
            <span class="set-time"
                  @click.stop="onClickTime">设置冬夏时间</span>
            <RadioGroup v-model="classes"
                        @on-change="onChangeRadioGroup">
              <Radio :label="1">固定班</Radio>
              <Radio :label="2">冬夏班</Radio>
            </RadioGroup>
            <Poptip trigger="hover">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
              <div slot="content">
                <p class="li-title">固定班:不会根据季节更改上下班时间</p>
                <p class="li-title">冬夏班:会根据季节更改上下班时间</p>
                <p class="li-title">当前系统内,10月01号~05月01号为冬季,05月01号~10月01号为夏季</p>
              </div>
            </Poptip>
          </div>
        </div>
        <div class="time-cont"
             v-if="classes == 1">
          <ul class="time-cont-list"
              v-for="(item,index) in timeList"
              :key="index">
            <li>
              <p class="li-title"><span class="li-must">*</span>考勤段名称：</p>
              <Input v-model="item.name"
                     maxlength="30"
                     v-stringLimit></Input>
            </li>
            <li>
              <p class="li-title"><span class="li-must">*</span>上班时间：</p>
              <TimePicker format="HH:mm"
                          placeholder=""
                          v-model="item.goWorkTime"></TimePicker>
            </li>
            <li>
              <p class="li-title"><span class="li-must">*</span>下班时间：</p>
              <TimePicker format="HH:mm"
                          placeholder=""
                          v-model="item.offWorkTime"></TimePicker>
            </li>
            <li v-show="index != 0"
                @click.stop="onClickDetailTime(index)">
              <Icon custom="i-icon icon-shanchu1"
                    size="16"
                    color="#25bb96"
                    style="line-height:32px" />
              <span class="m-l-5">删除</span>
            </li>
          </ul>
        </div>
        <div class="time-cont season-cont"
             v-if="classes == 2">
          <ul class="time-cont-list"
              v-for="(item,index) in timeList"
              :key="index">
            <li>
              <p class="li-title"><span class="li-must">*</span>考勤段名称：</p>
              <Input v-model="item.name"
                     maxlength="30"
                     v-stringLimit></Input>
            </li>
            <li>
              <p class="li-title"><span class="li-must">*</span>夏上班时间：</p>
              <TimePicker format="HH:mm"
                          placeholder=""
                          v-model="item.goWorkTimeSummer"></TimePicker>
            </li>
            <li>
              <p class="li-title"><span class="li-must">*</span>夏下班时间：</p>
              <TimePicker format="HH:mm"
                          placeholder=""
                          v-model="item.offWorkTimeSummer"></TimePicker>
            </li>
            <li>
              <p class="li-title"><span class="li-must">*</span>冬上班时间：</p>
              <TimePicker format="HH:mm"
                          placeholder=""
                          v-model="item.goWorkTimeWinter"></TimePicker>
            </li>
            <li>
              <p class="li-title"><span class="li-must">*</span>冬下班时间：</p>
              <TimePicker format="HH:mm"
                          placeholder=""
                          v-model="item.offWorkTimeWinter"></TimePicker>
            </li>
            <li v-show="index != 0"
                @click.stop="onClickDetailTime(index)">
              <Icon custom="i-icon icon-shanchu1"
                    size="16"
                    color="#25bb96"
                    style="line-height:32px" />
              <span class="m-l-5">删除</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="rule modal-cont"
           v-if="ruleType == '2'">
        <div class="rule-title">
          <div class="rule-left">
            <span>规则设置</span>
          </div>
        </div>
        <div class="rule-cont">
          <ul class="form-ul">
            <li class="form-ul-li half">
              <p class="li-title">允许上班提前：</p>
              <Input class="rule-cont-input"
                     v-model="workEarly"
                     maxlength="30"
                     v-intLimit
                     clearable></Input>
              <span class="li-unit">分钟</span>
            </li>
            <li class="form-ul-li half">
              <p class="li-title">迟到：</p>
              <Input class="rule-cont-input"
                     v-model="late"
                     maxlength="30"
                     v-intLimit
                     clearable></Input>
              <span class="li-unit">分钟</span>
            </li>
            <li class="form-ul-li half">
              <p class="li-title">严重迟到：</p>
              <Input class="rule-cont-input"
                     v-model="severityLate"
                     maxlength="30"
                     v-intLimit
                     clearable></Input>
              <span class="li-unit">分钟</span>
            </li>
            <li class="form-ul-li half">
              <p class="li-title">早退：</p>
              <Input class="rule-cont-input"
                     v-model="leaveEarly"
                     maxlength="30"
                     v-intLimit
                     clearable></Input>
              <span class="li-unit">分钟</span>
            </li>
            <!-- <li class="form-ul-li half">
              <p class="li-title">下班缺卡：</p>
              <Input class="rule-cont-input"
                     v-model="offWorkLackCard"
                     maxlength="30"
                     v-intLimit
                     clearable></Input>
              <span class="li-unit">分钟</span>
            </li>
            <li class="form-ul-li half">
              <p class="li-title">上班缺卡：</p>
              <Input class="rule-cont-input"
                     v-model="onWorkLackCard"
                     maxlength="30"
                     v-intLimit
                     clearable></Input>
              <span class="li-unit">分钟</span>
            </li> -->
            <li class="form-ul-li">
              <p class="li-title">旷工：</p>
              <Input class="rule-cont-input"
                     v-model="absenteeism"
                     maxlength="30"
                     v-intLimit
                     clearable></Input>
              <span class="li-unit">分钟</span>
              <span class="li-desc">(当旷工值为"0",按照上下班都为缺卡统计旷工,有固定值按照固定值计算)</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="remark flex a-center">
        <p class="li-title">备注：</p>
        <Input v-model="remark"
               maxlength="-1"
               :rows="3"
               type="textarea"
               placeholder="备注" />
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>

    <!-- 设置冬夏时间 -->
    <WorkTimeModal v-model="workTimeVisible"
                   :dataId.sync="workTimeId"></WorkTimeModal>
  </Modal>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
import WorkTimeModal from '@/components/product/admin/modal/work/WorkTimeModal'
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    MyTreeSelect,
    WorkTimeModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '班组'
    },
    dataId: String,
    ruleType: Number
  },
  data () {
    return {
      loading: false,
      name: '', // 班组名称
      department: '',  // 所属机构
      departmentId: '',  // 所属机构id
      classes: 1, // 班组类型
      workEarly: 5, // 允许上班提前
      late: 5, // 迟到
      severityLate: 10, // 严重迟到
      leaveEarly: 5, // 早退
      offWorkLackCard: 5, // 下班缺卡
      onWorkLackCard: 20, // 上班缺卡
      absenteeism: 0, // 旷工
      remark: '', // 备注
      // departmentTreeArr: [],
      timeList: [
        {
          name: '',
          goWorkTime: '',
          offWorkTime: '',
          goWorkTimeSummer: '',
          offWorkTimeSummer: '',
          goWorkTimeWinter: '',
          offWorkTimeWinter: '',
        }
      ],
      // 设置冬夏时间
      workTimeVisible: false,
      workTimeId: null
    };
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList'
    ]),
    init () {
      this.updateDepartmentTreeList()
    },
    getDetail () {
      this.$store.dispatch('getWorkGroupDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    // 选择机构
    onChangeDepartment (section) {
      this.department = section.name
      this.departmentId = section.id
    },
    // 固定班与冬夏班单选切换
    onChangeRadioGroup (value) {
      console.log(value);
      this.timeList = [{
        name: '',
        goWorkTime: '',
        offWorkTime: '',
        goWorkTimeSummer: '',
        offWorkTimeSummer: '',
        goWorkTimeWinter: '',
        offWorkTimeWinter: '',
      }]
    },
    // 添加时间段
    onClickAddTime () {
      this.timeList.push({})
    },
    // 删除时间段
    onClickDetailTime (i) {
      this.timeList.splice(i, 1)
    },
    // 设置冬夏时间
    onClickTime () {
      this.workTimeVisible = true
      this.workTimeId = this.ruleType
    },
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入班组名称')
      if (!this.department) return this.$Message.info('请选择所属机构')
      // 固定班
      if (this.classes == 1) {
        for (const item of this.timeList) {
          if (!item.name) return this.$Message.info('请输入考勤段名称')
          if (!item.goWorkTime) return this.$Message.info('请输入上班时间')
          if (!item.offWorkTime) return this.$Message.info('请输入下班时间')
        }
      }
      // 冬夏班
      if (this.classes == 2) {
        for (const item of this.timeList) {
          if (!item.name) return this.$Message.info('请输入考勤段名称')
          if (!item.goWorkTimeSummer) return this.$Message.info('请输入夏季上班时间')
          if (!item.offWorkTimeSummer) return this.$Message.info('请输入夏季下班时间')
          if (!item.offWorkTimeWinter) return this.$Message.info('请输入冬季下班时间')
          if (!item.offWorkTimeWinter) return this.$Message.info('请输入冬季下班时间')
        }
      }
      if (this.ruleType == 2) {
        if (!this.absenteeism == 0) {
          if (this.absenteeism <= this.severityLate) return this.$Message.info('旷工需大于严重迟到')
        }
      }
      this.loading = true
      let params = {
        name: this.name,
        department: this.department,
        departmentId: this.departmentId,
        classes: this.classes,
        remark: this.remark,
        timeList: this.timeList
      }
      if (this.dataId) params.workGroupId = this.dataId
      params.workEarly = this.workEarly
      params.late = this.late
      params.severityLate = this.severityLate
      params.leaveEarly = this.leaveEarly
      params.offWorkLackCard = this.offWorkLackCard
      params.onWorkLackCard = this.onWorkLackCard
      params.absenteeism = this.absenteeism
      params.module = this.ruleType
      this.$store.dispatch('createOrEditWorkGroup', params).then(res => {
        this.loading = false
        // this.$store.dispatch('updatePersonTreeList', true)
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
      this.department = ''
      this.departmentId = ''
      this.classes = 1
      this.workEarly = 5
      this.late = 5
      this.severityLate = 10
      this.leaveEarly = 5
      this.offWorkLackCard = 5
      this.onWorkLackCard = 20
      this.absenteeism = 0
      this.remark = ''
      this.timeList = [
        {
          name: '',
          goWorkTime: '',
          offWorkTime: '',
          goWorkTimeSummer: '',
          offWorkTimeSummer: '',
          goWorkTimeWinter: '',
          offWorkTimeWinter: '',
        }
      ]
    }
  },
}
</script>

<style lang='scss' scoped>
.modal-cont {
  max-height: 500px;
}
.time {
  .time-title {
    display: flex;
    align-content: center;
    justify-content: space-between;
    .time-left {
      display: flex;
      align-content: center;
      span {
        // display: inline-block;
        width: 100px;
        height: 26px;
        padding-right: 9px;
        line-height: 26px;
        text-align: right;
        border-left: 2px solid #25bb96;
        font-weight: 600;
        color: #666;
      }
      p {
        width: 100px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border: 1px solid #25bb96;
        color: #25bb96;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .time-right {
      color: #25bb96;
      .set-time {
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  .time-cont {
    .time-cont-list {
      display: flex;
      margin-top: 20px;
      li {
        width: 25%;
        display: flex;
        line-height: 32px;
        p {
          min-width: 100px;
          text-align: right;
          .li-must {
            color: #ff0000;
            font-size: 16px;
            margin-right: 2px;
          }
        }
        ::v-deep {
          .ivu-date-picker {
            .ivu-date-picker-rel {
              .ivu-input-wrapper {
                .ivu-input-suffix {
                  width: 20px;
                }
                .ivu-input {
                  padding-right: 20px;
                }
              }
            }
          }
        }
      }
      li:last-child {
        padding-left: 40px;
        color: #25bb96;
        cursor: pointer;
      }
    }
  }
  .season-cont {
    .time-cont-list {
      li {
        width: 18%;
      }
      li:last-child {
        width: 10%;
        padding-left: 40px;
        color: #25bb96;
        cursor: pointer;
      }
    }
  }
}
.remark {
  margin-top: 20px;
  p {
    min-width: 100px;
    text-align: right;
  }
}
.rule {
  margin-top: 20px;
  .rule-title {
    display: flex;
    align-content: center;
    justify-content: space-between;
    .rule-left {
      display: flex;
      align-content: center;
      span {
        // display: inline-block;
        width: 100px;
        height: 26px;
        padding-right: 9px;
        line-height: 26px;
        text-align: right;
        border-left: 2px solid #25bb96;
        font-weight: 600;
        color: #666;
      }
    }
  }
  .rule-cont {
    margin-top: 20px;
    .rule-cont-input {
      width: 65px !important;
      margin-right: 5px;
    }
    .li-desc {
      color: #999;
      margin-left: 10px;
      line-height: 32px;
    }
  }
}
</style>